import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrDatePicker from '../components/FrDatePicker'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getData } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const pageName='agenda'

const swal = withReactContent(Swal)

function Agenda(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboLoc, setComboLoc] = useState([{display: 'Online', value: ''}])

  const columns = [
    { id: 'NOME', numeric: false, label: 'Nome', align: 'left', Width: 100 },
    { id: 'LOCAL', numeric: false, label: 'Local', align: 'left', minWidth: 150 },
    { id: 'FORMATDIA', numeric: false, label: 'Data', align: 'left', minWidth: 150, order: 'DIA' },
    { id: 'CASE', numeric: false, label: 'Dia', align: 'left', minWidth: 150 },
    { id: 'FORMATHORARIO', numeric: false, label: 'Horário', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setenableAdd(await api.getAcessos(pageName, 'Inclusao'))
      setenableEdit(await api.getAcessos(pageName, 'Alteracao'))
      setenableDel(await api.getAcessos(pageName, 'Exclusao'))
      let loc = await api.getComboData('locais')
      let locais = [{DISPLAY: 'Online', VALUE: '33'}]
      locais.push(loc)
      console.log(locais.flat(), loc)
      setComboLoc(locais.flat())



      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(query)
      query.usu_logado = auth.user
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        console.log(pageName)
        getData(event, setData, setFiltered, pageName)
      }
    }

    fetchData()
  }, [])

  function getExtraData(e) {
   return
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/intcc/agenda?usu_logado='+auth.user);
    document.getElementById('searchForm').reset()
    setFilter({usu_logado: auth.user})
  }



  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  const refreshGrid = () => {
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    api.get('/agenda', { params: queryObject }).then(r => {
      setData(r.data)
      console.log(r.data)
      swal.close()
    })
  }

 

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Agenda</h3>
          <UserOptions  />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.usu_logado || ''}
                style={{ width: 330 }}
                name="usu_logado"
                id="usu_logado"
                color="#389492"
                label="usu_logado"
                variant="outlined"
                size="small"
                hidden
                onChange={handleChange}
              />
              
              <FrSelect
                value={filter.fk_sal || ''}
                style={{ width: 340 }}
                name="fk_sal"
                id="fk_sal"
                label='Local'
                data={comboLoc}
                onChange={handleChange}
              />
              <FrTextInput
                value={filter.paciente || ''}
                style={{ width: 330 }}
                name="paciente"
                id="paciente"
                color="#389492"
                label="Paciente"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrDatePicker
                value={(filter.dataIni || '').split('T')[0]}
                name="dataIni"
                id="dataIni"
                color="#528b46"
                label="Data Inicial"
                variant="outlined"
                onChange={handleChange}
                size="small"
                style={{ width: 180 }}
    
              />
              <FrDatePicker
                value={(filter.dataFim || '').split('T')[0]}
                name="dataFim"
                id="dataFim"
                color="#528b46"
                label="Data Final"
                variant="outlined"
                onChange={handleChange}
                size="small"
                style={{ width: 180 }}
    
              />

            </div>
            <div>
              <button onClick={(e) => getData(e, setData, setFiltered, pageName)}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/intcc/agenda/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable 
            regName={pageName} 
            checkin='S' 
            columns={columns} 
            searched={filtered} 
            refreshGrid={refreshGrid}  
            codeName="PK_AGE" 
            uploadComprovante='S' 
            page={pageName} 
            data={data}  
            enableEdit={true} 
            enableDel={false} 
          />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Agenda;
