import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrTextField from '../components/FrTextField'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrameF2 from '../components/FrameF2'
import {colors} from '../utils/vars'

import api from '../services/api'
import { getUserCode, paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';

const swal = withReactContent(Swal)

function RegAgenda(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authIntcc'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboTerapeuta, setComboTerapeuta] = useState([{ value: '', display: '' }])
  const [comboMotivoAlta, setComboMotivoAlta] = useState([{ value: '', display: '' }])
  const [comboTipoRelatos, setComboTipoRelatos] = useState([{ value: '', display: '' }])
  const [comboAtivo, setComboAtivo] = useState([{ VALUE: 'S', DISPLAY: 'Sim' }, { VALUE: 'N', DISPLAY: 'Não' }])
  const [comboPro, setComboPro] = useState([{ VALUE: 'S', DISPLAY: 'Sim' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [pac, setPac] = useState(0)
  const [comboHorario, setComboHorario] = useState([{DISPLAY: '', VALUE: ''}])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('agenda', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('agenda', 'Inclusao'))
      }
      setComboTerapeuta(await api.getComboData('terapeuta'))
      setComboTipoRelatos(await api.getComboData('tipo_relatos'))
      setComboMotivoAlta(await api.getComboData('motivo_alta'))
      setComboPro(await api.getComboData('pacientes', auth.user))
      setComboHorario([{DISPLAY: '08:00', VALUE: '0800'},
        {DISPLAY: '08:30', VALUE: '0830'},
        {DISPLAY: '09:00', VALUE: '0900'},
        {DISPLAY: '09:30', VALUE: '0930'},
        {DISPLAY: '10:00', VALUE: '1000'},
        {DISPLAY: '10:30', VALUE: '1030'},
        {DISPLAY: '11:00', VALUE: '1100'},
        {DISPLAY: '11:30', VALUE: '1130'},
        {DISPLAY: '12:00', VALUE: '1200'},
        {DISPLAY: '12:30', VALUE: '1230'},
        {DISPLAY: '13:00', VALUE: '1300'},
        {DISPLAY: '13:30', VALUE: '1330'},
        {DISPLAY: '14:00', VALUE: '1400'},
        {DISPLAY: '14:30', VALUE: '1430'},
        {DISPLAY: '15:00', VALUE: '1500'},
        {DISPLAY: '15:30', VALUE: '1530'},
        {DISPLAY: '16:00', VALUE: '1600'},
        {DISPLAY: '16:30', VALUE: '1630'},
        {DISPLAY: '17:00', VALUE: '1700'},
        {DISPLAY: '17:30', VALUE: '1730'},
        {DISPLAY: '18:00', VALUE: '1800'},
        {DISPLAY: '18:30', VALUE: '1830'},
        {DISPLAY: '19:00', VALUE: '1900'},
        {DISPLAY: '19:30', VALUE: '1930'},
        {DISPLAY: '20:00', VALUE: '2000'},
        {DISPLAY: '20:30', VALUE: '2030'},
        {DISPLAY: '21:00', VALUE: '2100'},
        {DISPLAY: '21:30', VALUE: '2130'},
        {DISPLAY: '22:00', VALUE: '2200'},
        {DISPLAY: '22:30', VALUE: '2230'}])
      
      if (query.codigo > 0) {
        console.log(auth)
        setUpdateType(true)
        api.get('/agenda?pk_age='+query.codigo + '&usu_logado='+getUserCode()).then(r=>{
          let auxValues = {...r.data[0]}
          console.log(auxValues)
          auxValues.DIA = auxValues.DIA.split('T')[0]
          setData(auxValues)
          swal.close()
        })
        
        // api.get('/prontuarios?pk_pro=' + query.codigo+'&usu_logado='+auth.user).then(r => {
        //   if (r.data[0]) {
        //     console.log(r.data[0])
        //     setData(r.data[0])
        //     swal.close()
        //   } else {
        //     swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
        //       .then((result) => {
        //         if (result.value) {
        //           window.history.back()
        //         }
        //       })
        //   }
        // })
      } else swal.close()
    }

    fetchData()
  }, [])

  useEffect(() => {
    let auxValues={...data}
    if (data.FK_TRE == 4){
      auxValues.FK_MAL = 4
    }
    setData(auxValues)
  }, [data.FK_TRE])

  function consistData(data) {
    let required = [ 
      'FK_PAC',
      'HORARIO',
      'DIA'
    ]
    
   

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (fieldName == 'FK_PAC') fieldName = 'Paciente'
      if (fieldName == 'HORARIO') fieldName = 'Horário'
      if (fieldName == 'DIA') fieldName = 'Dia Atendimento'

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        auxValues.FK_USU = getUserCode()
        let errors = consistData(auxValues)
        // if(parseInt(auxValues.HORARIO) > parseInt(auxValues.HORARIO_FIM)){
        //   errors.push('horário inicial maior que final')
        // }
        console.log(errors.length)
        
        if (errors.length === 0) {
          if (updateType) {
            api.post('/agenda/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/agenda/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }

        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            text: "Verifique o(s) campo(s) " + errors.join(', '),
            icon: 'warning',
          })
        }
      }
    })
  }


  function procedimentoAlta(auxValues){
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>PROCEDIMENTO DE ALTA DO PACIENTE</h2>`,
      html: `<p style='color:${colors.info900}'>Atenção!!! Todos os agendamentos deste prontuário serão excluídos da agenda. Deseja prosseguir?</p>`,
      icon: 'question',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then( r => {
    
      if (r.value) {
          
        
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    console.log(e.target.value)
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Agendamento</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

        <div class="row" style={{marginLeft: '0px'}}>

          <FrSelect
            value={data.FK_PAC || ''}
            onChange={handleChange}
            name="FK_PAC"
            id="FK_PAC"
            label='Paciente'
            data={comboPro}
            style={{ width: 320 }}
          />
          <FrameF2 tipoModal={'Pacientes'} data={data} pac={pac} setPac={setPac} ></FrameF2>

        </div> 
        {/* horario = mysql.escape(req.body.horarioIni)
        let horarioFim = mysql.escape(req.body.horarioFim)
      
        let obs = mysql.escape(req.body.obs)          */}

          <FrDatePicker
            value={(data.DIA || '')}
            name="DIA"
            id="DIA"
            color="#528b46"
            label="Data Atendimento"
            variant="outlined"
            size="small"
            onChange={handleChange}
            style={{ width: 190 }}
          />
          <FrSelect
            value={data.HORARIO || ''}
            style={{ width: 340 }}
            name="HORARIO"
            id="HORARIO"
            label='Horário'
            data={comboHorario}
            onChange={handleChange}
          />
          <br /> 
          <FrTextField
            maxLength='1000'
            value={data.OBSERVACAO || ''}
            onChange={handleChange}
            name="OBSERVACAO"
            id="OBSERVACAO"
            color="#389492"
            label="Observações"
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            style={{ width: 882 }}
          />

          
          


        </form>
        <div className="regButtonsDiv">
          {enableEdit ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{1==0 ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegAgenda;
